// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import BSAShortHeader from '../../components/BestSchoolsAwards/Header/ShortHeader'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const BSATerms = () => {
  return (
    <>
      <Helmet>
        <title>
          Pricing | Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <BSAShortHeader />
      <BSAContent>
        <div className={ContentStyles.NarrowContent}>
          <h1>Pricing</h1>
          <p>
            The first category submission of the Muddy Stilettos Best Schools
            Awards is FREE to enter, and thereafter, each further submission is
            charged at £65 per category entry.
          </p>
          <ul>
            <li>First category entry: FREE </li>
            <li>All further category entries: £65 each </li>
          </ul>
          <p>Please note: there is one free entry per whole school.</p>
          <p style={{ textAlign: 'center' }}>
            <Link
              className={ContentStyles.RegisterInterestButton}
              to="/best-schools-awards/dashboard/enter"
            >
              Get Started
            </Link>
          </p>
        </div>
      </BSAContent>
      <BSAFooter />
    </>
  )
}

export default BSATerms
